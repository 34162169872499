import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Bulletov3 from "../../contracts/Bulletov3.json";
import getWeb3 from "../../getWeb3";
import { addAddressApi, communityDetailsApi,boardPurchase, packageDetailsApi, compoundSubscriptionApi, addressDetailApi, dataDetailsApi, withdrawApi } from '../../utils';
import Web3Modal from "web3modal";
import Web3 from "web3";

import WalletConnectProvider from "@walletconnect/web3-provider";
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org/'
      },
      network: 'binance',
    }
  }
};

const initialState = {
  loading:false,
  is_connected:false,
  web3: null,
  accounts: null,
  contract: null,
  contractAddress: null,
  contractAddress_Short:'-',
  sponsor_address: '',
  userInfo:{},
  networkMain:false,
  YourAddress: '-',
  YourAddressfull: null,
  amountBNB:null,
  user_referer:'-',
  balance:null,
  adminFee:null,
  getDepositLength:0,
  withdrawl_amount:'',
  globalInfo:{
    systemUserCount: 0,
    systemInvest:0,
    systemWithdraw:0
  },
  userDetails:{
    userDeposit:0,
    userWithdraw:0,
    userReferredBy:'-',
    userReferredByFull:'-',
    binaryIncome:0,
    directIncome:0,
    binarySponsorIncome:0,
    walletBalance:0,
    businessForNextRank:0,
    reinvestmentWalletBalance:0
  },
  currentProfileRank:{},
  nextProfileRank:{},
  communityDetails:{
    downline_list:[]
  },
  allPackages:{},
  selected_package:false,
  UsdToBnb:0,
  range_usd_price:0,
  invest_status:false,
  address_detail:null,
  subscriptionList:null,
  withdrawList:null,
  poolDetail:null,
  sponsorTree:null,
  levelDetail:null,
  address_block_list:[],
  is_sync_show:false,
  disable_address:'0x2E3F39422a9494f4a8DAE547bce3F4A34a42E3541',
  package: [],
  // package: [1,2,3,4,5,6],
  packageIndex:0,
  // admin_new:null,
  // userSide:(this.props.match.params.hasOwnProperty('side') && this.props.match.params.side  != null) ? this.props.match.params.side : 0,
  // userSide:'L',
  maximumWithdraw:100,
  currentSubAmount:0,
  minimumWithdraw:'0.01',
  DirectList:{},
}

const Package = [0.1,0.2,0.4,0.8,1.6,3.2];
class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:false,
        is_connected:false,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        contractAddress_Short:'-',
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        getDepositLength:0,
        withdrawl_amount:'',
        globalInfo:{
          systemUserCount: 0,
          systemInvest:0,
          systemWithdraw:0
        },
        userDetails:{
          userDeposit:0,
          userWithdraw:0,
          userReferredBy:'-',
          userReferredByFull:'-',
          binaryIncome:0,
          directIncome:0,
          binarySponsorIncome:0,
          walletBalance:0,
          businessForNextRank:0
        },
        currentProfileRank:{},
        nextProfileRank:{},
        communityDetails:{
          downline_list:[]
        },
        allPackages:{},
        selected_package:false,
        UsdToBnb:0,
        range_usd_price:0,
        invest_status:false,
        address_detail:null,
        subscriptionList:null,
        withdrawList:null,
        poolDetail:null,
        sponsorTree:null,
        levelDetail:null,
        address_block_list:[],
        is_sync_show:false,
        disable_address:'0x2E3F39422a9494f4a8DAE547bce3F4A34a42E3541',
        package: [],
        // package: [1,2,3,4,5,6],
        packageIndex:0,
        // admin_new:null,
        // userSide:(this.props.match.params.hasOwnProperty('side') && this.props.match.params.side  != null) ? this.props.match.params.side : 0,
        // userSide:'L',
        maximumWithdraw:100,
        currentSubAmount:0,
        minimumWithdraw:'0.01',
        DirectList:{}
      }
    }
    
    // componentDidMount = async () => {
    //   try {
    //     // Get network provider and web3 instance.
    //     const web3 = await getWeb3();
    //     // Use web3 to get the user's accounts.
    //     //setInterval(async()=>{
    //       const accounts = await web3.eth.getAccounts();
         
    //       if(this.state.accounts !== accounts[0]){
    //       // Get the contract instance.
    //       const networkId = await web3.eth.net.getId();
    //       //alert(networkId);
    //       //if(networkId !== 56) throw "Please connect Mainnet"; 
    //       const deployedNetwork = Bulletov3.networks[networkId];
    //       const instance = new web3.eth.Contract(
    //         Bulletov3.abi,
    //         deployedNetwork && deployedNetwork.address,
    //       );   
    //       //this.setState({loading:false});
    //       let user_account = accounts[0];
    //       //let user_account = '0xf766ee95604AcE11A0dd37606cD177b423826A68';
    //       //user_account = '0x2E3F39422a9494f4a8DAE547bce3F4A34a42E354';
    //       // user_account = '0xbed6ccd8a5cf8ec6a3ef95a3019c221c492f5286';
    //       // user_account = '0xeD7b359b42ba554Ee6E9ccC07998afB2172B687C';
    //       // user_account = '0xedC47a9880a59d4b450B1c06c19E7A6343C4a403';
    //       this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
    //       }
    //    //},1000);
    //   } catch (error) {
    //     // Catch any errors for any of the above operations.
    //     // alert(
    //     //   error
    //     // );
    //     this.setState({networkMain:false});
    //     //console.error(error);
    //   }
    // };

    componentDidMount = async () => {
      try {
        let web3Modal = new Web3Modal({ cacheProvider: true, providerOptions });
        if (web3Modal.cachedProvider) {
          let web3ModalInstance = await web3Modal.connect();
          await web3ModalInstance.enable();
          let web3 = new Web3(web3ModalInstance);
          let accounts = await web3.eth.getAccounts();
  
          // Subscribe to accounts change
          web3ModalInstance.on("accountsChanged", (accounts) => {
            console.log(accounts);
            console.log(this.state.accounts);
            if(accounts.length){
              //accounts = accounts;
              this.setState({accounts:accounts[0]});
            }else{
              //this.disconnectFromWallet();
              //this.connectToWallet();
            }
            //this.disconnectFromWallet();
            this.connectToWallet();
          });
  
          // Subscribe to chainId change
          web3ModalInstance.on("chainChanged", (chainId) => {
            this.disconnectFromWallet();
            //this.connectToWallet();
          });
  
          // Subscribe to provider connection
          web3ModalInstance.on("connect", (info) => {            
            this.connectToWallet();
          });
  
          // Subscribe to provider disconnection
          web3ModalInstance.on("disconnect", (error) => {           
            this.disconnectFromWallet();
          });
  
          const networkId = await web3.eth.net.getId();
          //if(this.state.accounts){
          if(networkId !== 56) {
            this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
            this.setState({loading:false});
            return false;            
          }
          //}
          // Use web3 to get the user's accounts.
  
          if(this.state.accounts !== accounts[0]){
            // Get the contract instance.
            
            const deployedNetwork = Bulletov3.networks[networkId];
            const instance = new web3.eth.Contract(
              Bulletov3.abi,
              deployedNetwork && deployedNetwork.address,
            );
  
            let user_account = accounts[0];
  
            this.setState({loading:false, is_connected:true});
            this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
        }
  
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false, loading:false});
        //console.error(error);
      }
    };

    connectToWallet = async (e) => {
      //e.preventDefault();
      //alert('kk');
      try {        
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
        // Use web3 to get the user's accounts.
        const accounts = await web3.eth.getAccounts();
  
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        if(networkId !== 56) {
          this.props.enqueueSnackbar("Error! Please connect to bsc mainnet",{ variant: 'error' });
          this.setState({loading:false});
          return false;
        }
         
        if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          
          this.setState({loading:true});
          const deployedNetwork = Bulletov3.networks[networkId];
          const instance = new web3.eth.Contract(
            Bulletov3.abi,
            deployedNetwork && deployedNetwork.address,
          );
  
          let user_account = accounts[0];
  
          this.setState({loading:false, is_connected:true});
          this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }else{
          this.setState({loading:false, is_connected:true});
          this.props.enqueueSnackbar("Wallet Connected",{ variant: 'success' });
        }
      } catch (error) {
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});
        console.error(error);
        this.setState({loading:false});
      }      
    };
  
    disconnectFromWallet = async () => {
      try {
        this.setState({loading:true});
        let web3Modal = new Web3Modal({ cacheProvider: false, providerOptions });
        
        await web3Modal.clearCachedProvider();
  
        //await web3Modal.current.clearCachedProvider();
        //window.localStorage.clear();
        if(this.state.web3){
          this.props.enqueueSnackbar("Wallet disconnected",{ variant: 'success' });
        }
        
        this.setState(initialState);
      } catch (error) {
        console.error('error disc',error);
        // Catch any errors for any of the above operations.
        this.props.enqueueSnackbar(error.message,{ variant: 'error' });
        this.setState({networkMain:false});        
        this.setState({loading:false});
      } 
    }   
  

    fetchData = async() => {
      const { accounts, contract, contractAddress } = this.state;
      //accounts = '0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c';
      //accounts = '0xBa498a4F5d11d6de8a20533EA94A9C512AD2e28D';
      //accounts = '0x41dC82912494cAFc2854dfe2201CCFFEA5B949fd';

      //console.log(this.state.contractAddress);
      let contractAddress_Short = contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length);
      let YourAddress = accounts.slice(0, 5) + '...' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      //console.log(accounts);
      //let userInfo = await contract.methods.getUserInfo(accounts).call();
      let userInfo = await contract.methods.userdata(accounts).call();
      //console.log(userInfo);
      if(userInfo.amount){
        userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      }
      // if(userInfo.totalIncomeNew){
      //   userInfo.totalIncome = userInfo.totalIncomeNew/1e8;
      //   userInfo.totalIncome = parseFloat(userInfo.totalIncome).toFixed(5);
      // }
      // if(userInfo.withdrawanNew){
      //   userInfo.withdrawan = userInfo.withdrawanNew/1e8;
      //   userInfo.withdrawan = parseFloat(userInfo.withdrawan).toFixed(5);
      // }

      //console.log(userInfo);
     
      let user_referer = '-';
      if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
        user_referer = userInfo.refferal_code;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }

      
      let getDepositLength = await contract.methods.getDepositLength(accounts).call();
      let admin_new = await contract.methods.admin().call();

      this.setState({ 
        contractAddress_Short,
        YourAddress,
        YourAddressfull,
        userInfo,
        user_referer,
        getDepositLength,
        admin_new
      },async()=>{
        this.communityDetailsApi();        
        this.dataDetailsApi();
        this.packageDetailsApi();
      });     
    }

    paginate = (
      totalItems,
      currentPage = 1,
      pageSize = 10,
      maxPages = 10
    ) => {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
  
      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }
  
      let startPage, endPage;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }
  
    getPaginationData = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.dataDetailsApi(url,page,type);
    }

    communityDetailsApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await communityDetailsApi(this.state.accounts);
      //console.log(data);
      //const data = await communityDetailsApi('0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c');
      //const data = await communityDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
      //const data = await communityDetailsApi('TEwD1YadPF1ef388xbPwuCWFNGLFDXcJYp');
      //const data = await communityDetailsApi('0xC5d99c8B7E70a38423E7F4BA709d63686928537C');
      //const data = await communityDetailsApi('0x51ac3516b5964df862ef1F7b212313bF32aA8e35');
      //console.log('communityDetailsApi',data);      
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            systemUserCount: data.data.systemUserCount,
            systemInvest:data.data.systemInvest,
            systemWithdraw:data.data.systemWithdraw
          }})
        );
        let userReferredBy = data.data.userReferredBy;
        if(userReferredBy == 'admin'){
          
        }else{
          userReferredBy = data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length);
        }
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            userDeposit:data.data.userDeposit,
            userWithdraw:data.data.userWithdraw,
            userReferredByFull:data.data.userReferredBy ? data.data.userReferredBy : null,
            userReferredBy:data.data.userReferredBy ? userReferredBy : null,
            binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
            directIncome:parseFloat(data.data.sponsorIncome).toFixed(2),
            binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
            walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
            businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2),
            uplineIncome:parseFloat(data.data.uplineIncome).toFixed(4),
            levelIncome:parseFloat(data.data.levelIncome).toFixed(4),
            pool_income:parseFloat(data.data.pool_income).toFixed(4),
            boardIncome:parseFloat(data.data.boardIncome).toFixed(4),
            totalIncome:parseFloat(data.data.totalIncome).toFixed(4),
            is_only_board:data.data.nextPackage.is_only_board,
            board_amount:data.data.nextPackage.board_amount,
            uplineList:data.data.uplineList,
            currentupline:data.data.currentPackage.upline,
            reinvestmentWalletBalance:parseFloat(data.data.reinvestmentWalletBalance).toFixed(4)
             
          }})
        );
        
        this.setState({currentProfileRank:data.data.currentProfileRank,
          // minimumWithdraw:parseFloat(data.data.minimumWithdraw).toFixed(2),
          maximumWithdraw:parseFloat(data.data.maximumWithdraw).toFixed(2),
          currentSubAmount:data.data.currentSubAmount?parseFloat(data.data.currentSubAmount).toFixed(2):0,
          nextProfileRank:data.data.nextProfileRank});

      }else{
        
        this.setState({loading:false, invest_status:false});
      }
    }

    packageDetailsApi = async()=>{
      
      const data = await packageDetailsApi();
      //console.log(data);
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            systemUserCount: data.data.systemUserCount,
            systemInvest:data.data.systemInvest,
            systemWithdraw:data.data.systemWithdraw
          }})
        );
      
        this.setState({
          package:data.data});
        
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    addressDetailApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await addressDetailApi(this.state.accounts);
      //const data = await addressDetailApi('xcdgvxdgesdfdsfwsfr33');
      //console.log(data.data.details);
      if(data.status){
        this.setState({
          address_detail:data.data.details,
          loading:false});
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    dataDetailsApi = async(url=null,page=null,type=null)=>{
      if(!this.state.accounts){
        return false;
      }
      //const data = await dataDetailsApi('admin',url,page,type);
      const data = await dataDetailsApi(this.state.accounts,url,page,type);
      //const data = await dataDetailsApi('0xa3c86f5dee63aef205e52bc3b00151634dcd7594',url,page,type);
      //console.log('dataDetailsApi',data);
      if(data.status){
        // let totalIncome = parseFloat(data.data.uplineIncome) +
        // parseFloat(data.data.boardIncome) + parseFloat(data.data.binaryIncome);
        // this.setState(({userDetails})=>
        //   ({userDetails : {...userDetails,
        //     binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
        //     directIncome:parseFloat(data.data.directIncome).toFixed(2),
        //     binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
        //     walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
        //     businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2)
        //   }})
        // );
  
        let subscriptionList = data.data.subscriptionList;
        let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
        subscriptionList.pages = subscriptionListPagination.pages;
  
        let withdrawList = data.data.withdrawList;
        let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
        withdrawList.pages = withdrawListPagination.pages;
  
        let poolDetail = data.data.poolDetail;
        let poolDetailPagination = this.paginate(poolDetail.total,poolDetail.current_page,poolDetail.per_page,10);
        poolDetail.pages = poolDetailPagination.pages;
  
        let levelDetail = data.data.levelDetail;
        let levelDetailPagination = this.paginate(levelDetail.total,levelDetail.current_page,levelDetail.per_page,10);
        levelDetail.pages = levelDetailPagination.pages;

        let DirectList = data.data.directDetail;
        // DirectList= Object.values(DirectList);
        console.log(data.data.subscriptionList)
        console.log(data.data.withdrawList)
        this.setState({
          subscriptionList:data.data.subscriptionList,
          withdrawList:data.data.withdrawList,
          poolDetail:data.data.poolDetail,
          // sponsorTree:data.data.sponsorTree,
          levelDetail:data.data.levelDetail,
          DirectList,
          loading:false        
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    doJoinNow = async () => {
      //let amountUSD = document.getElementById("range_value").innerText;
      // let userInfo = await contract.methods.userdata(this.state.accounts).call();
      let packageIndex = this.state.packageIndex;
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      if(!this.state.selected_package){
        this.props.enqueueSnackbar("Please select a package first!",{ variant: 'error' })        
        return false;
      }

      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.userdata(accounts).call();
      if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
        sponsor_address = userInfo.refferal_code;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.web3.utils.isAddress(sponsor_address) || /^0x00+$/.test(sponsor_address)){
        this.props.enqueueSnackbar("Sponsor Address is invalid!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      // if(buyStatus){
      //   this.props.enqueueSnackbar("You only buy once",{ variant: 'error' })
      //   this.setState({sponsor_addressError:true});
      //   return false;
      // }

      let getDepositLength = await contract.methods.getDepositLength(sponsor_address).call();
      
      if(sponsor_address != this.state.admin_new){
        if(getDepositLength == 0){
          this.props.enqueueSnackbar("Invalid Referral User!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
        }
      }
      
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let amountBNB;
      amountBNB = Package[packageIndex];
      //alert(amountBNB);
      
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true});
            const data = await addAddressApi(this.state.accounts,sponsor_address);

            let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
            let invest = await this.state.contract.methods.invest(sponsor_address,packageIndex).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );

            if(invest.status){              
              this.setState({amountBNB:null, range_usd_price:null, loading:false, invest_status:true});
              this.props.enqueueSnackbar("Joined Successfully! Data will be reflected within few minutes!",{ variant: 'success' });
              this.fetchData();
            }else{
              this.setState({loading:false, invest_status:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');
            }
            
          }
          catch(err) {
            this.setState({loading:false, invest_status:false});
            if (err.message.includes("User denied transaction signature")) {
              // handle the "error" as a rejection
              this.props.enqueueSnackbar(err.message,{ variant: 'error' });
            }else{
              this.props.enqueueSnackbar(err,{ variant: 'error' });
            }
          }          
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
        }        
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doboardPurchase = async () => {
      //if(parseFloat(this.state.userInfo.amount) > 0){
        try {
          this.setState({loading:true});
          // let withdrawal = await this.state.contract.methods.userWithdrawal().send(
          //   { 
          //     from: this.state.accounts
          //   }
          // );

          const board = await boardPurchase(this.state.accounts);
          console.log(board)

          if(board.status){
            this.setState({amountBNB:null,loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });
            this.fetchData();
          }else{
            let msg = 'Some Network Error Occurred!';
            if(board.error){
              msg = board.error;
            }
            this.setState({loading:false});
            this.props.enqueueSnackbar(msg);
          }
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }         
      // }else{
      //   this.props.enqueueSnackbar('Insurenderfficient balance!');
      // }
           
    }
    doClaim = async () => {
      //if(parseFloat(this.state.userInfo.amount) > 0){
        try {
          this.setState({loading:true});
          // let withdrawal = await this.state.contract.methods.userWithdrawal().send(
          //   { 
          //     from: this.state.accounts
          //   }
          // );

          const hhh = await communityDetailsApi(this.state.accounts,true);
          console.log(hhh)

          if(hhh.status){
            this.setState({amountBNB:null,loading:false});
            this.props.enqueueSnackbar("Claim Successfully!",{ variant: 'success' });
            this.fetchData();
          }else{
            let msg = 'Some Network Error Occurred!';
            if(hhh.error){
              msg = hhh.error;
            }
            this.setState({loading:false});
            this.props.enqueueSnackbar(msg);
          }
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }         
      // }else{
      //   this.props.enqueueSnackbar('Insurenderfficient balance!');
      // }
           
    }
    doWithdrawal = async () => {
      let available_withdraw_balance = this.state.userDetails.walletBalance;
      let minimumWithdraw = this.state.minimumWithdraw;
      console.log(this.state.minimumWithdraw,available_withdraw_balance)
      if(parseFloat(available_withdraw_balance).toFixed(2) < minimumWithdraw){
        this.props.enqueueSnackbar(`Minimum Withdraw Amount requires 0.01 BNB!`,{ variant: 'error' });        
        return false;
      }
      console.log('k')
      //if(parseFloat(this.state.userInfo.amount) > 0){
        try {
          this.setState({loading:true});
          // let withdrawal = await this.state.contract.methods.userWithdrawal().send(
          //   { 
          //     from: this.state.accounts
          //   }
          // );

          const withdrawal = await withdrawApi(this.state.accounts);
          console.log(withdrawal)

          if(withdrawal.status){
            this.setState({amountBNB:null,loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });
            this.fetchData();
          }else{
            let msg = 'Some Network Error Occurred!';
            if(withdrawal.error){
              msg = withdrawal.error;
            }
            this.setState({loading:false});
            this.props.enqueueSnackbar(msg);
          }
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }         
      // }else{
      //   this.props.enqueueSnackbar('Insurenderfficient balance!');
      // }
           
    }
    
    render() {
      let range_value = this.state.range_usd_price;
      // if(document.getElementById('range_value')){
      //   range_value = document.getElementById('range_value').innerText;
      // }
      let lastPackageIndex =1;
      let available_wthdraw_balance = 0;
      if(this.state.userInfo && this.state.userInfo.totalIncome){
        available_wthdraw_balance = this.state.userInfo.totalIncome - this.state.userInfo.withdrawan;
      }

      var holder = {};
      var count = {};
      var sponsor_list = [];
      var totalRefCount = 0;
      var totalRefInvestment = 0;
      if(this.state.sponsorTree){
        this.state.sponsorTree.forEach(function(d) {
          if (holder.hasOwnProperty(d.level)) {
            holder[d.level] = holder[d.level] + d.investment;
            count[d.level] = parseInt(count[d.level]) + 1;
          } else {
            holder[d.level] = d.investment;
            count[d.level] = 1;
          }
        });

        let this2 = this;

        for (var prop in holder) {
          if(prop > 0){
            totalRefCount += count[prop];
            totalRefInvestment += holder[prop];
          }
          
          sponsor_list.push({ level: prop, investment: holder[prop], count: count[prop] });
        }
      }

      let actualSubscription = parseFloat(this.state.userDetails.userTotalSubscription).toFixed(4)-parseFloat(this.state.userDetails.userCompoundSubscription).toFixed(4);
      actualSubscription = parseFloat(actualSubscription).toFixed(4);

      

      console.log(this.state.DirectList);

      let this2 = this;
      
      return (
        <Frame withFooter={false} withHeader={false} isHome={false} user_address={this.state.YourAddressfull} user_address_short={this.state.YourAddress}>
          {this.state.loading ? (                  
           <div className="preloader__wrap">
           <div className="preloader__box">
              <div className="robot">
                 <img src="/image/logo-icon.png" alt="img" />
              </div>
           </div>
        </div>
          ) : (null)}
            {this.state.loading ? (                  
              <div className="loader-container">
                <div className="loader-logo">
                  <div className="loader-circle"></div>
                </div>
              </div>
            ) : (null)}

            <header  className="header-section">
              <div  className="container">
                <div  className="header-wrapper">
                    <div  className="logo-menu">
                      <a href="/"  className="logo">
                          <img src="/image/logo.png" alt="logo" />
                      </a>
                      <a href="/"  className="small__logo d-xl-none">
                          <img src="/image/logo-icon.png" alt="logo" />
                      </a>
                    </div>
                    <div  className="menu__right__components compoent__middle d-flex align-items-center">
                      <div  className="menu__components">
                          <div  className="tolly__shop">
                          {this.state.is_connected ? (
                            <>
                            
                              <a style={{cursor:'pointer'}} disabled={this.state.loading} onClick={()=>this.disconnectFromWallet()}>
                                <img className='check_not_img' src="/image/check.png" alt="" />
                                <img className='wallet_img' src="/image/wallet.png" alt="Wallet-menu" />
                              </a>
                            </>
                             
                          ):(
                            <>
                            
                             <a  style={{cursor:'pointer'}} disabled={this.state.loading} onClick={()=>this.connectToWallet()}>
                             <img className='check_not_img' src="/image/cross.png" alt="" />
                              <img className='wallet_img' src="/image/wallet.png" alt="Wallet-menu" />
                             </a>
                            </>
                             
                          )} 
                          
                          </div>
                          <a target="_blank" href={`https://bscscan.com/address/${Bulletov3.networks[56].address}`}  className="cmn--btn">View Contract</a>

                      </div>
                       <div  className="header-bar d-lg-none">
                          <span></span>
                          <span></span>
                          <span></span>
                      </div>
                    </div>
                    <ul  className="main-menu wallet_menu">
                           <li> <a style={{cursor:'pointer'}} href="/wallet" className="d-flex">
                              <span>Wallet</span>
                            </a> </li>
                         <li> <a target="_blank" href={`https://bigbull.live/app/en/board-tree/${this.state.YourAddressfull}/1`}  className="d-flex">My Matrix</a></li>
                      
                    </ul> 
                    <div  className="menu__right__components right__com d-flex align-items-center">
                      <div  className="menu__components">
                          <div  className="tolly__shop">
                          {this.state.is_connected ? (
                            <>
                              <a style={{cursor:'pointer'}} disabled={this.state.loading} onClick={()=>this.disconnectFromWallet()}>
                              <img className='check_not_img' src="/image/check.png" alt="" />
                                <img className='wallet_img' src="/image/wallet.png" alt="Wallet-menu" />
                              </a>
                            </>
                            
                          ):(
                            <>
                            <a style={{cursor:'pointer'}} disabled={this.state.loading} onClick={()=>this.connectToWallet()}>
                            <img className='check_not_img' src="/image/cross.png" alt="" />
                              <img className='wallet_img' src="/image/wallet.png" alt="Wallet-menu" />
                            </a>
                            </>
                              
                          )} 
                          </div>
                          <a target="_blank" href={`https://bscscan.com/address/${Bulletov3.networks[56].address}`}  className="cmn--btn">View Contract</a>
                          {/* {this.state.is_connected ? (
                            <a style={{cursor:'pointer'}} disabled={this.state.loading} onClick={()=>this.disconnectFromWallet()} className="cmn--btn">
                              <span>Wallet Disconnect</span>
                            </a>
                          ):(
                            <a style={{cursor:'pointer'}} className="cmn--btn" disabled={this.state.loading} onClick={()=>this.connectToWallet()}>
                              <span>Wallet Connect</span>
                            </a>
                          )}
                          <a  target="_blank" href={`https://bigbull.live/app/en/board-tree/${this.state.YourAddressfull}/1`}  className="cmn--btn">My Matrix</a>
                          <a  target="_blank" href={`https://bscscan.com/address/${Bulletov3.networks[56].address}`}  className="cmn--btn">View Contract</a> */}
                      </div>
                    </div>
                </div>
              </div>
            </header>
           
            <section className="banner__section breadcumnd__banner bannerbg">
              <div className="banner__bgmask">
                  <img src="/image/box-element.png" alt="mask" />
              </div>
              <div className="container">
                  <div className="breadcumnd__wrapper">
                    <div className="breadcumnd__content">
                        <h1 className="title">Wallet</h1>
                      </div>
                    <div className="breadcumnd__thumb">
                        <img src="/image/bnb-coin.png" alt="bread" />
                    </div>
                    <div className="bread__ai">
                        <img src="/image/t-element.png" alt="img" />
                    </div>
                  </div>
              </div>
            </section>

            <section className="feature__section bg__white  pt-5 pb-5">
              <div className="container">
                <div className='row'>
                  <div className='col-md-6 col-lg-6'>
                    <div className="wallet_box_bg">
                      
                      

                      {/* <div className="form-group">
                        <label className="form-label">Choose your Side</label>
                        <select disabled={this.state.currentSubAmount>0} className="form-control" value={this.state.userSide} onChange={(e)=>this.setState({userSide:e.target.value})}>
                            <option value={0}>Left</option>
                            <option value={1}>Right</option>
                        </select>
                      </div> */}
                      <div className="form-group">
                          <label className="form-label">Sponsor wallet address</label>
                          {this.state.user_referer != '-'?(
                              <input type="text" className="form-control" placeholder="Sponsor Address"
                              readOnly
                              value={this.state.user_referer || '-'}
                              //onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                              />
                          ):(
                              <input type="text"className="form-control" placeholder="Sponsor Address"
                              value={this.state.sponsor_address || ''}
                              onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                              />
                          )}
                      </div>
                      <ul className="trx_btn mb-3">
                          {this.state.package.map((amount,key) => {
                            let view_amount = parseInt(amount);
                            let class_ = '';
                            if(this.state.userInfo?.buyStatus){
                              
                              if(this.state.userInfo?.buyStatus && parseInt(this.state.userInfo?.lastPackageIndex)+1==key){
                                // let price = parseFloat(amount)-parseFloat(this.state.currentSubAmount);
                                view_amount = amount                                                
                              }else{
                                class_ = 'disabled';
                              }
                            }

                            if(!this.state.userInfo?.buyStatus){
                              
                              if(key==0){
                                // let price = parseFloat(amount)-parseFloat(this.state.currentSubAmount);
                                view_amount = amount                                                
                              }else{
                                class_ = 'disabled';
                              }
                            return(
                              <li key={key}>
                                {!this.state.userInfo?.buyStatus && key==0?(
                                      <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                      onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                      {/* {view_amount+' USD'} */}
                                      {amount}
                                    </button>
                                ):(
                                  <button disabled className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                  onClick={e => this.setState({amountUSD:amount,packageIndex:key})}>
                                  {/* {view_amount+' USD'} */}
                                  {amount}
                                </button>
                                )}
                            
                            </li>
                              

                            )
                            
                            }
                            
                            
                            return(
                              <li key={key}>
                                {this.state.userInfo?.buyStatus && parseInt(this.state.userInfo?.lastPackageIndex)+1==key?(
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                ):(
                                  <button disabled className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                )}
                                
                              </li>
                          )                              
                          })}
                      </ul>
                      <div className='text-center'>
                      <button onClick={this.doJoinNow} disabled={this.state.loading} className="cmn--btn btn-block">Register</button>
                      </div>
                      
                    </div>
                  </div>
                  <div className='col-md-6 col-lg-6'>
                      
                      <div className="wallet_box_bg">
                        <div className='row'>
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="form-group">
                              <label className="form-label">Total withdrawl Amount</label>        
                                  <input type="text" className="form-control" placeholder="Sponsor Address"
                                  readOnly
                                  value={this.state.userDetails?.walletBalance}
                                  //onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                                  />
                            
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-lg-12 text-center">
                          <button onClick={this.doWithdrawal} disabled={this.state.loading} className="cmn--btn btn-block">Withdraw</button>
                          </div>
                        </div>
                      {this.state.userDetails.is_only_board==1?(
                          <div className='row'>
                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="form-group">
                              <label className="form-label"> Buy Matrix</label>        
                                  <input type="text" className="form-control" placeholder="Sponsor Address"
                                  readOnly
                                  value={this.state.userDetails?.board_amount}
                                  //onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                                  />
                             
                            </div>
                          </div>
                          <div className="col-md-12 col-sm-12 col-lg-12 text-center">
                          <button onClick={this.doboardPurchase} disabled={this.state.loading} className="cmn--btn">Buy Matrix</button>
                          </div>
                          </div>
                      ):(null)}
                    </div>
                    <h6 className="text-white mb-2 mt-3">Referral Link </h6>
                      <div className="d-flex d-flex align-items-center mb-4"> 
                        <CopyToClipboard text={`https://bigbull.live/wallet/${this.state.YourAddressfull}`}
                          onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                          <button title="Copy Your Referal Link" className="copy_btn mr-2"><img src="/image/copy.png" alt="" /></button>
                        </CopyToClipboard> 
                            <div className="text-white"> {`https://bigbull.live/wallet/${this.state.YourAddress}`}</div>
                      </div>
                  </div>
                </div>
                
              </div>
              <div className="container pt-3">
                  <div className="row">
                  <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{parseFloat(this.state.userDetails.userDeposit).toFixed(4)} BNB</h5>
                        <p>Total Deposit</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.userDetails.totalIncome} BNB</h5>
                        <p>Total Income</p>
                      </div>
                    </div>

                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{parseFloat(this.state.userDetails.directIncome).toFixed(4)} BNB</h5>
                        <p>Sponsor Income</p>
                      </div>
                    </div>

                    {/* <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.userDetails.uplineIncome} BNB <button onClick={this.doClaim} disabled={this.state.loading} className="reload_btn" ><img src="/image/reload.png" alt="" /></button></h5>
                        <p>Upline Income</p>
                      </div>
                    </div> */}

                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.userDetails.levelIncome} BNB</h5>
                        <p>Level Income</p>
                      </div>
                    </div>
                    
                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.userDetails.boardIncome} BNB</h5>
                        <p>Matrx Income</p>
                      </div>
                    </div>
                   
                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.userDetails.pool_income} BNB</h5>
                        <p>Pool Income</p>
                      </div>
                    </div>
                  
                    
                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.user_referer}</h5>
                        <p>Referred By</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-6">
                      <div className="personal_info_box">
                        <h5>{this.state.userDetails.reinvestmentWalletBalance} BNB</h5>
                        <p>Board Amount</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-4 col-sm-6 col-lg-4">
                      <div className="personal_box_bg d-flex align-items-center">
                        <div className="fifty">
                          <p>System <br /> user count</p>
                        </div>
                        <div className="fifty">
                          <h4>{this.state.globalInfo.systemUserCount}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-lg-4">
                      <div className="personal_box_bg d-flex align-items-center">
                        <div className="fifty">
                          <p>System <br /> Investment </p>
                        </div>
                        <div className="fifty">
                          <h4>{parseFloat(this.state.globalInfo.systemInvest).toFixed(4)} <br/> BNB</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-lg-4">
                      <div className="personal_box_bg d-flex align-items-center">
                        <div className="fifty">
                          <p>System <br /> Withdrawal </p>
                        </div>
                        <div className="fifty">
                          <h4>{parseFloat(this.state.globalInfo.systemWithdraw).toFixed(4)} <br/>BNB</h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-lg-4">
                      <div className="personal_box_bg d-flex align-items-center">
                        <div className="fifty">
                          <p> Withdrawal<br /> Taken </p>
                        </div>
                        <div className="fifty">
                          <h4>{parseFloat(this.state.userDetails.userWithdraw).toFixed(4)} <br/>BNB</h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-sm-6 col-lg-4">
                      <div className="personal_box_bg d-flex align-items-center">
                        <div className="fifty">
                          <p>Available <br /> Withdrawal </p>
                        </div>
                        <div className="fifty">
                          <h4>{parseFloat(this.state.userDetails.walletBalance).toFixed(4)} <br/>BNB</h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
              </div>

             
              <div className="feature__rocket">
                  <img src="/image/usd-coin.png" alt="rocket"/>
              </div>
              <div className="light__element1">
                  <img src="/image/light-elements.png" alt="light"/>
              </div>
              <div className="light__element2">
                  <img src="/image/light-elements.png" alt="light"/>
              </div>
              <div className="light__element3">
                  <img src="/image/light-elements.png" alt="light"/>
              </div>
              <div className="light__element4">
                  <img src="/image/light-elements.png" alt="light"/>
              </div>
            </section>


           

            <section className="pt-5 pb-5 feature__section ">
              <div className="container">
                  <div className="section__header">
                    <h2 className="wow fadeInUp" data-wow-duration="2s">
                        Community Level
                    </h2>
                  </div>
                  <div className="teble-box">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" data-bs-toggle="tab" href="#table_tab1" role="tab" aria-selected="true">Subscription History</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#table_tab3" role="tab" aria-selected="false">Withdraw List</a>

                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#table_tab5" role="tab" aria-selected="false">Directs Data</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#table_tab4" role="tab" aria-selected="false">Level Income</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#table_tab2" role="tab" aria-selected="false">Pool Details</a>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <a className="nav-link" data-bs-toggle="tab" href="#table_tab6" role="tab" aria-selected="false">Upline list</a>
                        </li> */}
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="table_tab1" role="tabpanel">
                        <div className="table_box">
                          <div className="table-responsive dark_table">
                            <table className="table mb-0">
                                <thead>
                                <tr>
                                  <th>Package Amount</th>
                                  <th>Matrx Amount</th>
                                  {/* <th>ROI Percentage</th> */}
                                  <th>Activation Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                  {this.state.subscriptionList ? (
                                    this.state.subscriptionList.data.length>0 ? (
                                      this.state.subscriptionList.data.map(function(val, index){   
                                                          
                                        return (
                                          <tr key={`subs${index}`}>
                                            <td>{parseFloat(val.price).toFixed(4)} BNB</td>
                                            <td>{parseFloat(val.board_amount).toFixed(4)} BNB</td>
                                            {/* <td>{parseFloat(val.daily_return_percentage).toFixed(2)}%</td> */}
                                            <td>{val.created_at}</td>
                                          </tr>
                                        )
                                      })
                                    ):(
                                      <tr>
                                        <td colSpan="3" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                    )
                                    ) : (
                                      <tr>
                                        <td colSpan="3" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                  )}
                                </tbody>
                            </table>
                            {this.state.subscriptionList ? (
                              <div className="text-center">
                                <ul className="table_pagination mb-0">
                                  <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                                    <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}>Prev</a>
                                  </li>
                                  {this.state.subscriptionList.pages.length>0 ? (
                                    this.state.subscriptionList.pages.map(function(val, index){
                                      let className = "";
                                      if(val == this2.state.subscriptionList.current_page){
                                        className = "active";
                                      }
                                      return (
                                        <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                                          <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                                        </li>
                                      )
                                    })
                                  ):(null)}
                                  <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                                    <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} >Next</a>
                                  </li>
                                </ul>
                              </div>
                            ):(null)}
                          </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="table_tab2" role="tabpanel">
                        <div className="table_box">
                            <div className="table-responsive dark_table">
                              <table className="table mb-0">
                                <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Pool Amount</th>
                                  <th>Total user</th>
                                  <th>Total Pool Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                  {this.state.poolDetail ? (
                                    this.state.poolDetail.data.length>0 ? (
                                      this.state.poolDetail.data.map(function(val, index){                        
                                        return (
                                          <tr key={`income${index}`}>
                                            <td>{val.process_date}</td>
                                            <td>{parseFloat(val.per_user_pool_amount).toFixed(4)} BNB</td>
                                            <td>{val.user_count}</td>
                                            <td>{parseFloat(val.total_pool_amount).toFixed(4)} BNB</td>
                                          </tr>
                                        )
                                      })
                                    ):(
                                      <tr>
                                        <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {this.state.poolDetail ? (
                                <div className="text-center">
                                  <ul className="table_pagination mb-0">
                                    <li  style={{cursor:this.state.poolDetail.prev_page_url?'pointer':'initial'}}>
                                      <a onClick={()=>this.getPaginationData(this.state.poolDetail.prev_page_url,null,null,this.state.poolDetail.current_page,this.state.poolDetail.last_page)}>Prev</a>
                                    </li>
                                    {this.state.poolDetail.pages.length>0 ? (
                                      this.state.poolDetail.pages.map(function(val, index){
                                        let className = "";
                                        if(val == this2.state.poolDetail.current_page){
                                          className = "active";
                                        }
                                        return (
                                          <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.poolDetail.current_page?'pointer':'initial'}}>
                                            <a onClick={()=>this2.getPaginationData(null,'income_page',val,null,null)} >{val}</a>
                                          </li>
                                        )
                                      })
                                    ):(null)}
                                    <li style={{cursor:this.state.poolDetail.next_page_url?'pointer':'initial'}}>
                                      <a onClick={()=>this.getPaginationData(this.state.poolDetail.next_page_url,null,null,this.state.poolDetail.current_page,this.state.poolDetail.last_page)} >Next</a>
                                    </li>
                                  </ul>
                                </div>
                              ):(null)}
                            </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="table_tab3" role="tabpanel">
                          
                        <div className="table_box">
                            <div className="table-responsive dark_table">
                              <table className="table mb-0">
                                <thead>
                                <tr>
                                  <th>Tx Hash</th>
                                  <th>Total Amount</th>
                                  <th>Withdraw Amount</th>
                                  <th>Reinvest Amount</th>
                                  <th>Status</th>
                                  <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                  {this.state.withdrawList ? (
                                    this.state.withdrawList.data.length>0 ? (
                                      this.state.withdrawList.data.map(function(val, index){   
                                        let hash='------';
                                        if(val.t_hash){
                                          hash = val?.t_hash?.slice(0, 5) + '.....' + val?.t_hash?.slice(val.t_hash.length-5, val?.t_hash.length)
                                        }             
                                        return (
                                          <tr key={`income${index}`}>
                                            <td>{hash}</td>
                                            <td>{parseFloat(val.amount_from).toFixed(4)} BNB</td>
                                            <td>{parseFloat(val.withdrawable_amount).toFixed(4)} BNB</td>
                                            <td>{parseFloat(val.reinvestment_amount).toFixed(4)} BNB</td>
                                            <td>{val.status}</td>
                                            <td>{val?.process_at||''}</td>
                                          </tr>
                                        )
                                      })
                                    ):(
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="6" className="text-center">
                                          No Data Available!
                                        </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {this.state.withdrawList ? (
                                <div className="text-center">
                                  <ul className="table_pagination mb-0">
                                    <li  style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                                      <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}>Prev</a>
                                    </li>
                                    {this.state.withdrawList.pages.length>0 ? (
                                      this.state.withdrawList.pages.map(function(val, index){
                                        let className = "";
                                        if(val == this2.state.withdrawList.current_page){
                                          className = "active";
                                        }
                                        return (
                                          <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                                            <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)} >{val}</a>
                                          </li>
                                        )
                                      })
                                    ):(null)}
                                    <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                                      <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)} >Next</a>
                                    </li>
                                  </ul>
                                </div>
                              ):(null)}
                            </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="table_tab4" role="tabpanel">
                        <div className="table_box">
                            <div className="table-responsive dark_table">
                              <table className="table mb-0">
                                <thead>
                                <tr>
                                  <th>Level</th>
                                  <th>Amount</th>
                                  <th>Discription</th>
                                  <th>Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                  {this.state.levelDetail ? (
                                    this.state.levelDetail.data.length>0 ? (
                                      this.state.levelDetail.data.map(function(val, index){                        
                                        return (
                                          <tr key={`income${index}`}>
                                            <td>{val.level}</td>
                                            <td>{parseFloat(val.amount).toFixed(4)}</td>
                                            <td>{val.description} USD</td>
                                            <td>{val.created_at} </td>
                                          </tr>
                                        )
                                      })
                                    ):(
                                      <tr>
                                        <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {this.state.levelDetail ? (
                                <div className="text-center">
                                  <ul className="table_pagination mb-0">
                                    <li  style={{cursor:this.state.levelDetail.prev_page_url?'pointer':'initial'}}>
                                      <a onClick={()=>this.getPaginationData(this.state.levelDetail.prev_page_url,null,null,this.state.levelDetail.current_page,this.state.levelDetail.last_page)}>Prev</a>
                                    </li>
                                    {this.state.levelDetail.pages.length>0 ? (
                                      this.state.levelDetail.pages.map(function(val, index){
                                        let className = "";
                                        if(val == this2.state.levelDetail.current_page){
                                          className = "active";
                                        }
                                        return (
                                          <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.levelDetail.current_page?'pointer':'initial'}}>
                                            <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)} >{val}</a>
                                          </li>
                                        )
                                      })
                                    ):(null)}
                                    <li style={{cursor:this.state.levelDetail.next_page_url?'pointer':'initial'}}>
                                      <a onClick={()=>this.getPaginationData(this.state.levelDetail.next_page_url,null,null,this.state.levelDetail.current_page,this.state.levelDetail.last_page)} >Next</a>
                                    </li>
                                  </ul>
                                </div>
                              ):(null)}
                            </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="table_tab5" role="tabpanel">
                        <div className="table_box">
                            <div className="table-responsive dark_table">
                              <table className="table mb-0">
                                <thead>
                                <tr>
                                  <th>Address</th>
                                  <th >Total Package</th>
                                  <th>Total Investment</th>
                                  <th>Total Income</th>
                                </tr>
                                </thead>
                                <tbody>
                                  {this.state.DirectList ? (
                                    this.state.DirectList.length>0 ? (
                                      this.state.DirectList.map(function(val, index){                        
                                        return (
                                          <tr key={`direct${index}`}>
                                            <td>{val.username.slice(0, 5) + '.....' + val.username.slice(val.username.length-5, val.username.length)}</td>
                                            <td>{parseFloat(val.subscription_ratio).toFixed(4)} BNB</td>
                                            <td>{parseFloat(val.subscription_for_upline_calculation).toFixed(4)} BNB</td>
                                            <td>{parseFloat(val.total_income).toFixed(4)} BNB</td>
                                          </tr>
                                        )
                                      })
                                    ):(
                                      <tr>
                                        <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                        </div>
                        </div>
                        <div className="tab-pane fade" id="table_tab6" role="tabpanel">
                        <div className="table_box">
                            <div className="table-responsive dark_table">
                              <table className="table mb-0">
                                <thead>
                                <tr>
                                  <th>Level</th>
                                  <th >Address</th>
                                  <th>Total Investment</th>
                                  {/* <th>Bouns</th> */}
                                </tr>
                                </thead>
                                <tbody>
                                  {this.state.userDetails.uplineList ? (
                                    this.state.userDetails.uplineList.length>0 ? (
                                      this.state.userDetails.uplineList.map(function(val, index){     
                                        let className = "";
                                          if(val.level<=this2.state.userDetails.currentupline){
                                            className = "text-success"
                                          }
                                        return (
                                          <tr key={`direct${index}`}>
                                            
                                            <td className={className}>{val.level}</td>
                                            <td className={className}>{val.username}</td>
                                            <td className={className}>{parseFloat(val.total_investment).toFixed(4)} BNB</td>
                                          </tr>
                                        )
                                      })
                                    ):(
                                      <tr>
                                        <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                      </tr>
                                    )
                                  ) : (
                                    <tr>
                                      <td colSpan="4" className="text-center">
                                          No Data Available!
                                        </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                        </div>
                        </div>
                    </div>
                  </div>
              </div>
            </section>

   

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;
