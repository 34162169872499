import React, { Component } from 'react';
import Bulletov3 from "../contracts/Bulletov3.json";

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const {isHome,user_address,user_address_short} = this.props;
      return (



        <header  className="header-section">
        <div  className="container">
           <div  className="header-wrapper">
              <div  className="logo-menu">
                 <a href="/"  className="logo">
                    <img src="/image/logo.png" alt="logo" />
                 </a>
                 <a href="/"  className="small__logo d-xl-none">
                    <img src="/image/logo-icon.png" alt="logo" />
                 </a>
              </div>
              <div  className="menu__right__components compoent__middle d-flex align-items-center">
                 <div  className="menu__components">
                    {/* <div  className="tolly__shop">
                       <a href="/wallet"><img src="/image/wallet.png" alt="Wallet-menu" /></a>
                    </div> */}
                    {!isHome?(
                       <a  target="_blank" href={`https://bigbull.live/app/en/board-tree/${user_address}/1`}  className="cmn--btn">My Matrix</a>
                    ):(null)}
                    <a target="_blank" href={`https://bscscan.com/address/${Bulletov3.networks[56].address}`}  className="cmn--btn">View Contract</a>
                 </div>
                  <div  className="header-bar d-lg-none">
                    <span></span>
                    <span></span>
                    <span></span>
                 </div>
              </div>
              <ul  className="main-menu">
                     <li> <a style={{cursor:'pointer'}} href="/wallet" className="d-flex">
                        <span>Wallet</span>
                        </a> </li>                    
               </ul>
             
              <div  className="menu__right__components right__com d-flex align-items-center">
                 <div  className="menu__components">
                   
                    {!isHome?(
                       <a  target="_blank" href={`https://bigbull.live/app/en/board-tree/${user_address}/1`}  className="cmn--btn">My Matrix</a>
                    ):(null)}
                  
                    <a  target="_blank" href={`https://bscscan.com/address/${Bulletov3.networks[56].address}`}  className="cmn--btn">View Contract</a>
                 </div>

                
              </div>
           </div>
        </div>
     </header>

      
      );
    }
  }

  export default Header;