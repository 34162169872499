import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer  className="footer__section">
        <div  className="container">
          <div  className="footer__wrapper">
             <div  className="footer__top">
                <div  className="row g-5">
                   <div  className="col-xxl-12">
                      <div  className="footer__widget">
                         <div  className="widget__head">
                            <a href="/"  className="footer__logo">
                               <img src="/image/logo.png" alt="logo" />
                            </a>
                         </div>
                         <p  className="pb__20">
                         BIg Bull, supported by Trust Wallet, Meta Mask, Token Pocket, Uniswap and Coinbase Wallet, stands out as a robust and all-encompassing solution tailored to address your diverse financial goals and requirements. With a versatile approach, we allow you to select the one that aligns perfectly with your preferences and security standards.

                         </p>
                        <p  className="pb__20">
                        What truly distinguishes us from the rest is our unwavering commitment to complete automation. So, trust and join BIg Bull NOW to simplify your financial journey while prioritizing security and user autonomy.
                        </p>
    
                      </div>
                   </div>
                  
                </div>
             </div>
             <div  className="footer__bottom">
                <p>
                   Copyright © 2023 Big Bull. All rights reserved.
                </p>
             </div>
          </div>
       </div>
    
       <div  className="footer__mask">
          <img src="/image/box-element.png" alt="mask" />
       </div>
    
    </footer>
      );
    }
  }

  export default Footer;