import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

class Home extends React.Component {
    constructor(props) {
      super(props);
     
    }

    render() {   
      return (
        <Frame withFooter={true} withHeader={true} isHome={true}>
          
        <div class="preloader__wrap">
            <div class="preloader__box">
                <div class="robot">
                    <img src="image/logo-icon.png" alt="img" />
                </div>
            </div>
        </div>
        <section  className="banner__section bannerbg">
            <div  className="banner__bgmask">
                <img src="image/box-element.png" alt="mask" />
            </div>

            <div  className="container">
                <div  className="banner__wrapper">
                    <div  className="row g-4  justify-content-between">
                        <div  className="col-xxl-6 col-xl-6 col-lg-6">
                        <div  className="banner__content">
                            <div  className="content__box">
                                <span  className="d3 mb-0  wow fadeInUp" data-wow-duration="2s">
                                First Matrix Network   
                                </span>
                                <span  className="d3 wow fadeInDown" data-wow-duration="4s">
                                with <span className="theme2">Passive </span>  Income
                                </span>
                                <p  className="wow fadeInUp" data-wow-duration="2s">
                                    Our Matrix network set us apart as we provide traditional networks and unique opportunities to all our users with Multiple streams of Income.
                                </p>
                                
                            </div>
                        
                            <div  className="ai__elements">
                                <img src="image/bnb-coin.png" alt="ai" />
                            </div>
                        </div>
                        </div>
                        <div  className="col-xxl-1 col-xl-1 col-lg-1"></div>
                        <div  className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-8">
                        <div  className="banner__thumb">
                            <div  className="thumb">
                                <img src="image/bull.webp" alt="banner" />
                            </div>
                            {/* <div  className="rocket__element">
                                <img src="image/usd-coin.png" alt="rocket" />
                            </div> */}
                        </div>
                        </div>
                        <div  className="col-xxl-1 col-xl-1 col-lg-1"></div>
                    </div>
                </div>
            </div>

            <div  className="ball__element">
                <img src="image/bnb.png" alt="ball" />
            </div>
            <div  className="banner__mask">
                <img src="image/shape-element1.png" alt="mask" />
            </div>
            <div  className="light__element1">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element2">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element3">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element4">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element5">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="dark__mask d-none">
                <img src="image/banner-shape1-dark.png" alt="mask" />
            </div>
        </section>

        <section  className="about__section bg__white pt-0 pb-0">
            <div  className="container">
                <div  className="row align-items-center flex-row-reverse">
                    <div  className="col-xxl-6 col-xl-6 col-lg-6">
                        <div  className="about__content">
                        <div  className="section__header">
                            <h2  className="wow fadeInUp" data-wow-duration="2s">
                            The Power of Decentralization
                            </h2>
                            <p  className="wow fadeInDown mb-4" data-wow-duration="2s">
                                Big bull, the pioneering crypto machine, is revolutionizing the world of digital currency investments through its 100% distribution model for multiple Income along with matrix Platform. With a focus on transforming BNB cryptocurrency into profitable investments via smart contracting, Big bull is at the forefront of financial innovation.
                            </p>
                            <p  className="wow fadeInDown" data-wow-duration="6s">
                                We provide a global platform that allows anyone to engage in a permissionless and decentralized network, ensuring that financial opportunities are accessible to all.
                            </p>
                        </div>
                    
                        </div>
                    </div>
                    <div  className="col-xxl-1 col-xl-1 col-lg-1"></div>
                    <div  className="col-xxl-4 col-xl-4 col-lg-4 col-md-9 col-sm-9">
                        <div  className="about__thumb">
                        <div  className="brain__element">
                            <img src="image/bnb-coin.png" alt="breain"/>
                        </div>
                        <img src="image/bullrightview.webp" alt=""/>
                        </div>
                    </div>
                    <div  className="col-xxl-1 col-xl-1 col-lg-1"></div>
                </div>
            </div>
            <div  className="about__shape1">
                <img src="image/ashape-elements.png" alt="about" />
            </div>
            <div  className="light__element1">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element2">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element3">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element4">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element5">
                <img src="image/light-elements.png" alt="light" />
            </div>
        </section>

       
        <section  className="qualified__section make__service__section bg__white pt-5 pb-5">
            <div  className="airobot">
                <img src="image/usd-coin.png" alt="robot" />
            </div>
            <div  className="container">
               <div className='row'>
                    <div className='col-md-4 col-lg-4'>
                        <div className='Matrices_box'>
                            <h5>Matrices</h5>
                            <p>Our Unique matrices investment system on our platform allows users to increase their investment profit.</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4'>
                        <div className='Matrices_box'>
                            <h5>12-Slot Matrix Structure</h5>
                            <p>Our versatile 12-slot structure allows you to tailor your investment strategy to your preferences and goals.</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4'>
                        <div className='Matrices_box'>
                            <h5>BNB Rewards</h5>
                            <p>We offer rewards through various Incomes within our ecosystem biggbull.live, enhancing your overall rewards experience.</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4'>
                        <div className='Matrices_box'>
                            <h5>Passive Income</h5>
                            <p>biggbull.live allows you to get unlimited Global Income from your 20 line Matrix. One can count this as a Passive income for ever.</p>
                        </div>
                    </div>
                    <div className='col-md-4 col-lg-4'>
                        <div className='Matrices_box'>
                            <h5>X Matrix BNB Pool</h5>
                            <p>BNB pool allows you to get, again a Passive Income, When One can reach to 12-Slot Matrix Structure. All Passive income distribute on everyday basis.</p>
                        </div>
                    </div>
                </div>             
            </div>
        
            <div  className="textgreen__light">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__elegr">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element2gr">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="light__element3">
                <img src="image/light-elements.png" alt="light" />
            </div>
            <div  className="checkai">
                <img src="image/bnb-coin.png" alt="ai" />
            </div>
        </section>

      

     
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;